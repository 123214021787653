@import url("./fonts/fontello-icons/css/fontello.css");

/* SIMPLE RESET */
html{font-family:sans-serif;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%}body{margin:0}article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section,summary{display:block}audio,canvas,progress,video{display:inline-block;vertical-align:baseline}audio:not([controls]){display:none;height:0}[hidden],template{display:none}a{background-color:transparent}a:active,a:hover{outline:0}abbr[title]{border-bottom:1px dotted}b,strong{font-weight:700}dfn{font-style:italic}h1{font-size:2em;margin:.67em 0}mark{background:#ff0;color:#000}small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}sup{top:-.5em}sub{bottom:-.25em}img{border:0}svg:not(:root){overflow:hidden}figure{margin:1em 40px}hr{-moz-box-sizing:content-box;box-sizing:content-box;height:0}pre{overflow:auto}code,kbd,pre,samp{font-family:monospace,monospace;font-size:1em}button,input,optgroup,select,textarea{color:inherit;font:inherit;margin:0}button{overflow:visible}button,select{text-transform:none}button,html input[type=button],input[type=reset],input[type=submit]{-webkit-appearance:button;cursor:pointer}button[disabled],html input[disabled]{cursor:default}button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0}input{line-height:normal}input[type=checkbox],input[type=radio]{box-sizing:border-box;padding:0}input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button{height:auto}input[type=search]{-webkit-appearance:textfield;-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box}input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration{-webkit-appearance:none}fieldset{border:1px solid silver;margin:0 2px;padding:.35em .625em .75em}legend{border:0;padding:0}textarea{overflow:auto}optgroup{font-weight:700}table{border-collapse:collapse;border-spacing:0}td,th{padding:0}


/* FONTS */
@font-face {font-family: 'r100';src: url('fonts/MuseoSansRounded-100.eot');
src: local('☺'), url('fonts/MuseoSansRounded-100.woff') format('woff'), url('fonts/MuseoSansRounded-100.ttf') format('truetype'), url('fonts/MuseoSansRounded-100.svg') format('svg');font-weight: normal;font-style: normal;}

@font-face {font-family: 'r300';src: url('fonts/MuseoSansRounded-300.eot');
src: local('☺'), url('fonts/MuseoSansRounded-300.woff') format('woff'), url('fonts/MuseoSansRounded-300.ttf') format('truetype'), url('fonts/MuseoSansRounded-300.svg') format('svg');font-weight: normal;font-style: normal;}

@font-face {font-family: 'r500';src: url('fonts/MuseoSansRounded-500.eot');
src: local('☺'), url('fonts/MuseoSansRounded-500.woff') format('woff'), url('fonts/MuseoSansRounded-500.ttf') format('truetype'), url('fonts/MuseoSansRounded-500.svg') format('svg');font-weight: normal;font-style: normal;}


/* GENERAL */
* { box-sizing: border-box; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; outline: none; }

body { background: url(/src/images/lixa.jpg); font-family: 'r300'; font-size: 16px; }

h1, h2, h3, h4, h5, h6 { font-weight: 400; font-family: 'r300'; }

::-moz-selection { color: #fff; background: #ff4000; }
::selection { color: #fff; background: #ff4000; }

.viewport * ::-moz-selection { color: inherit; background: none; }
.viewport * ::selection { color: inherit; background: none; }

a { text-decoration: none; color: #495569; }
a:hover,
a:active { color: #ff4000; }

input { border-radius: 0; -webkit-appearance: none; }

/* BUDDY CLASSES */
.clearfix:before,
.clearfix:after { content: " "; display: table; }
.clearfix:after { clear: both; }
.clearfix { *zoom: 1; }
.hiddendesktop { display: none; }
.fleft { float: left; }
.fright { float: right; }
.clear { clear: both; }
.displaynone { display: none; }
.opacity0 { opacity: 0; }
.opacity1 { opacity: 1; }
.smalldevice { display: none; }
.mobiledevice { display: none; }
.mobiledevicehidden { display: block; } 

/* HEADER */
.mainheader { position: relative; }
.mainheader,
.mainheader * { transition: all .2s; }
.mainheader .square.logo { position: relative; }
.square,
.square * { transition: all .5s; }
.square { position: absolute; transition: all .5s; -webkit-transition: all .5s; text-align: center; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; align-items: center; -webkit-align-items: center; justify-content: center; -webkit-justify-content: center; }
.square.logo { left: 47%; top: 0; width: 6vw; height: 6vw;
background: #ff4000;
background: -moz-linear-gradient(-45deg, #ff4000 0%, #ad2e00 100%);
background: -webkit-linear-gradient(-45deg, #ff4000 0%,#ad2e00 100%);
background: linear-gradient(135deg, #ff4000 0%,#ad2e00 100%);
}
.mainheader.square.logo a { position: absolute; left: 0; top: 0; right: 0; bottom: 0; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; align-items: center; justify-content: center; }
.mainheader .square.logo a img { width: 80%; }

.backtop { border-top: 1px solid #cecece; background: url(images/lixa.jpg); position: relative; z-index: 2; height: 100px; cursor: pointer; }
.backtop .box { width: 30px; height: 30px; margin-top: 40px; transform: rotate(45deg); -webkit-transform: rotate(45deg); border-right: none; border-bottom: none; background: none; }
.backtop:hover .box,
.backtop:active .box { border-color: #777; }

.main { position: relative; text-align: center; padding-top: 2vw; overflow: hidden; transition: all 1s; }
.main.loading { opacity: 0; }
.main .linha { position: absolute; border-left: 1px solid #CECECE; left: 50%; top: 0; width: 1px; height: 100%; }
.main section { z-index: 2; position: relative; }

.main h1 { position: relative; margin: 20px auto 0 auto; text-align: center; color: #828282; text-transform: uppercase; font-size: 26px; line-height: 1.2; letter-spacing: 1vw; border: 1px solid #CECECE; padding: 20px 15px 15px 15px; background: url(images/lixa.jpg); width: 50%; }

.main section > h2 { margin: 0 auto; color: #828282; text-transform: uppercase; font-size: 2rem; line-height: 1; letter-spacing: 1vw; }

.box { position: relative; text-align: center; padding: 30px; border: 1px solid #CECECE; margin-left: auto; margin-right: auto; background: url(images/lixa.jpg); }

.skillbox { border-left: 0; border-right: 0; height: 10vw; margin-top: 7vw; }
.skills { position: absolute; display: table; width: 75vw; left: 12.5vw; top: 2.5vw; height: 5vw; table-layout: fixed; }
.skills .tr { display: none; position: absolute; top: 0; left: 0; right: 0; bottom: 0; }
.skills .td { display: table-cell; width: 5vw; height: 5vw; transition: all 2s; -webkit-transition: all 2s; text-align: center; vertical-align: middle; line-height: 1; font-weight: 700; color: #ff4000; font-family: 'Montserrat', sans-serif; font-size: 3vw; }
.main section.hall h4.intro { width: 33.33%; margin-top: 14vw; color: #777; font-size: 1vw; line-height: 1.6; font-family: "r100";}


/* WORK */
.main section.work > h2 { width: 66.66%; margin-top: 14vw; }
.heartbox { position: relative; height: 40vw; width: 66.66%; border-top: none; }
.heart { position: absolute; margin: 0 auto; left: 33.33vw; top: 16.66%; width: 33.33vw; height: 33.33vw; left: 25%; width: 50%; }
.heart .half { position: relative; width: 50%; float: left; height: 100%; }
.heart .half.right { margin-left: -1px; }
.heart .half .sq { width: calc(100% / 8); height: calc(50% / 8); position: absolute; background-color: #bbb; top: 0; right: 0; left: auto; transform: scale(3); -webkit-transform: scale(3); opacity: 0; transition: all .3s; }
.heart .sq.visivel { transform: scale(1); -webkit-transform: scale(1); opacity: 1; transition: all .5s; }
.heart .right .sq { background-color: #ff4000; right: auto; left: 0; }
.heart .sq img { position: absolute; width: auto; height: auto; max-width: 80%; max-height: 80%; top: 50%; left: 50%; transform: translate(-50%, -50%); -webkit-transform: translate(-50%, -50%); z-index: 2; }

.heart .sq.sqw2 { width: calc(100% / 4); }
.heart .sq.sqw3 { width: calc((100% / 8) * 3); }
.heart .sq.sqw4 { width: calc(100% / 2); }
.heart .sq.sqw5 { width: calc((100% / 8) * 5); }
.heart .sq.sqw6 { width: calc((100% / 8) * 6); }
.heart .sq.sqw8 { width: 100%; }

.heart .sq.sqh2 { height: calc(100% / 8); }
.heart .sq.sqh3 { height: calc((100% / 16) * 3); }
.heart .sq.sqh4 { height: calc(100% / 2); }

.heart .sq.r1 { right: calc(200% / 16); }
.heart .sq.r2 { right: calc(200% / 8); }
.heart .sq.r3 { right: calc((200% / 16) * 3); }
.heart .sq.r4 { right: calc((200% / 8) * 2); }
.heart .sq.r5 { right: calc((200% / 16) * 5); }

.heart .right .sq.r1 { left: calc(200% / 16); }
.heart .right .sq.r2 { left: calc(200% / 8); }
.heart .right .sq.r3 { left: calc((200% / 16) * 3); }
.heart .right .sq.r4 { left: calc((200% / 8) * 2); }
.heart .right .sq.r5 { left: calc((200% / 16) * 5); }

.heart .sq.top1 { top: calc(100% / 16); }
.heart .sq.top2 { top: calc((100% / 16) * 2); }
.heart .sq.top3 { top: calc((100% / 16) * 3); }
.heart .sq.top4 { top: calc((100% / 16) * 4); }
.heart .sq.top5 { top: calc((100% / 16) * 5); }
.heart .sq.top6 { top: calc((100% / 16) * 6); }
.heart .sq.top7 { top: calc((100% / 16) * 7); }
.heart .sq.top8 { top: calc((100% / 16) * 8); }
.heart .sq.top9 { top: calc((100% / 16) * 9); }
.heart .sq.top10 { top: calc((100% / 16) * 10); }
.heart .sq.top11 { top: calc((100% / 16) * 11); }
.heart .sq.top12 { top: calc((100% / 16) * 12); }

.heart .half .sq.vodafone { background-color: #888; }
.heart .half .sq.axa { background-color: #666; }
.heart .half .sq.axa img { max-width: 70%; }
.heart .half .sq.pmorris { background-color: #777; }
.heart .half .sq.best { background-color: #555; }
.heart .half .sq.mccann { background-color: #888; }
.heart .half .sq.mccann img { max-width: 60%; }
.heart .half .sq.netjets { background-color: #565252; }
.heart .half .sq.netjets img { top: 56%; }
.heart .half .sq.nos { background-color: #999; }
.heart .half .sq.nos img { max-width: 60%; }
.heart .half .sq.airbus { background-color: #777; }
.heart .half .sq.airbus img { max-width: 60%; }
.heart .half .sq.eristoff { background-color: #5F5E5E; }
.heart .half .sq.eristoff img { max-height: 70%; }
.heart .half .sq.continente { background-color: #888; }
.heart .half .sq.worten { background-color: #777; }
.heart a { position: absolute; top: 0; bottom: 0; right: 0; left: 0; }
.heart .gotoproj { position: absolute; z-index: 2; top: 16%; left: 9%; padding: 0 20px; text-align: left; transition: all .2s; }
.heart .gotoproj p { color: #fff; font-size: .8vw; font-family: 'r300'; margin: 0 0 3px 0; }
.heart .gotoproj h3 { font-size: 1.5vw; letter-spacing: 2px; font-family: 'Montserrat', sans-serif; margin: 0; color: #fff; }
.heart .gotoproj:hover { left: 20%; }
.heart .gotoproj h3:after { content: ""; position: absolute; width: 2vw; height: 2vw; left: -20%; border-top: 1px solid #fff; border-right: 1px solid #fff; opacity: 0; top: 10px; -ms-transform: rotate(45deg);  -webkit-transform: rotate(45deg); transform: rotate(45deg); transition: all .5s; }
.heart .gotoproj:hover h3:after,
.heart .gotoproj:active h3:after { left: -20%; opacity: 1; }




/* BIO */
.main section.bio > h2 { width: 33.33%; margin-top: 14vw; }
.biobox { position: relative; }
.biobox ul { position: relative; list-style: none; margin: 7vw 0 0 0; padding: 0; }
.biobox ul li { position: relative; visibility: hidden; }
.biobox ul li:after { content: ""; display: table; clear: both; margin-bottom: 50px; }
.biobox ul li .half { position: relative; float: left; text-align: left; width: 50%; padding: 30px; }
.biobox ul li .half img { max-height: 140px; }
.biobox ul li .half h2 { font-size: 2vw; color: #E63A00; text-transform: uppercase; display: inline-block; padding: 0 30px 15px 15px; border-bottom: 1px solid #CECECE;  width: 66.66%; margin: 0 -30px 0 auto; }
.biobox ul li .half h2 small { display: block; font-size: 1.25vw; margin-top: 5px; }
.biobox ul li .half.dir h2 { padding: 0 15px 15px 30px; margin: 0 auto 0 -30px; }
.biobox ul li .half p { font-size: 16px; color: #696969; line-height: 1.4; padding-left: 50%; text-align: right; font-family: "r100"; }
.biobox ul li .half p b { font-family: "r300"; display: block; }
.biobox ul li .half.dir p { padding-right: 50%; padding-left: 0; text-align: left; }
.biobox ul li .half.esq { text-align: right; }


/* CONTACT */
.main section.contact > h2 { width: 33.33%; margin-top: 7vw; margin-bottom: 120px; }
.contact .viewport {
-webkit-perspective: 800px;
-webkit-perspective-origin: 50% 200px;
-webkit-transform: scale(0.75,0.75);
-moz-perspective: 800px;
-moz-perspective-origin: 50% 200px;
-moz-transform: scale(0.75,0.75);
perspective: 800px;
perspective-origin: 50% 200px;
transform: scale(0.75,0.75);
margin: 150px 0;
}

.main section.contact section.cube { 
position: relative;
margin: 0 auto;
height: 400px;
width: 400px;
-webkit-transition: -webkit-transform 50ms linear;
-webkit-transform-style: preserve-3d;
-webkit-transform: rotateX(-10deg) rotateY(20deg);
-moz-transition: -moz-transform 50ms linear;
-moz-transform-style: preserve-3d;
-moz-transform: rotateX(-10deg) rotateY(20deg);
transition: transform 50ms linear;
transform-style: preserve-3d;
transform: rotateX(-10deg) rotateY(20deg);
}

.cube > div{position:absolute;height:400px;width:400px;padding:20px;background-color:rgba(50,50,50,0.9);font-size:1em;line-height:1em;color:#fff;}
.cube > div:first-child{-webkit-transform:rotateX(90deg) translateZ(200px);-moz-transform:rotateX(90deg) translateZ(200px);transform:rotateX(90deg) translateZ(200px)}
.cube > div:nth-child(2){-webkit-transform:translateZ(200px);-moz-transform:translateZ(200px);transform:translateZ(200px)}
.cube > div:nth-child(3){-webkit-transform:rotateY(90deg) translateZ(200px);-moz-transform:rotateY(90deg) translateZ(200px);transform:rotateY(90deg) translateZ(200px);text-align:center}
.cube > div:nth-child(4){-webkit-transform:rotateY(180deg) translateZ(200px);-moz-transform:rotateY(180deg) translateZ(200px);transform:rotateY(180deg) translateZ(200px)}
.cube > div:nth-child(5){-webkit-transform:rotateY(-90deg) translateZ(200px);-moz-transform:rotateY(-90deg) translateZ(200px);transform:rotateY(-90deg) translateZ(200px)}
.cube > div:nth-child(6){-webkit-transform:rotateX(-90deg) rotate(180deg) translateZ(200px);-moz-transform:rotateX(-90deg) rotate(180deg) translateZ(200px);transform:rotateX(-90deg) rotate(180deg) translateZ(200px)}

.cube > div { background: rgba(50,50,50,0.05); border: 1px solid #777; }
.cube > div:hover,
.cube > div.hovered { background: rgba(50,50,50,0.95); }
.cube > div:hover *,
.cube > div.hovered * { color: #fff; }

.cube p { position: absolute; margin: 0; text-align: center; left: 0; width: 100%; top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); font-size: 1.8vw; letter-spacing: .5vw; }
.cube img { width: 50%; top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); margin: auto; display: block; position: absolute; left: 25%; }
.cube p a { line-height: 1.5; color: #999; }
.cube p a:hover,
.cube p a:active,
.cube p a:hover span,
.cube p a:active span { color: #ff4000; }
.cube p a span { font-size: 1vw; line-height: 1; display: block; }
.cube .linkedin p a:hover i { color: #0077b5; }
.cube .projects p a:hover i,
.cube .cv p a:hover i { color: #ff4000; }
.cube .spotify p a:hover i { color: #1ed760; }
.cube .github p a:hover i { color: #000; }

p.box.altctct { color: #495569; margin-bottom: 0; border-bottom: none; }

/* PASSWORD */
.passwordpage .formbox { position: relative; padding-top: 2vw; }
.passwordpage .formbox h2 { margin-top: 0; width: 50%; border: none; border-bottom: 1px solid #CECECE; padding-bottom: 15px; }
.passwordpage .input-group { position: relative; display: flex; align-content: stretch; margin: 5rem auto 0 auto; max-width: 300px; padding: 0; border: none; }
.passwordpage .input-group input.pass { flex: 1 0 auto; background: #fff; border: 1px solid #fff; padding: 5px; font-size: 20px; padding: 1rem; }
.passwordpage .input-group .submeter { border: none; font-size: 18px; background: #495569; border: none; color: #ddd; padding: 1rem; }
.passwordpage .formbox p { margin-top: 3vw; color: #868789; }


/* CV */
.cvpage { padding-top: 2vw; }
.cvpage h2 { border: none; border-bottom: 1px solid #cecece; padding-top: 50px; }
.cvpage a.download { width: 33%; padding: 30px; font-size: 1.5rem; display: block; border: 1px solid #cecece; background: url(images/lixa.jpg); margin: 5vw auto; }
.cvpage a.download i { margin-left: 5px; font-size: 75%; }
.cvpage a:hover { color: #ff4000; }
.cvpage .extra { font-size: 16px; margin: 0 auto; color: #666; padding: 15px; border-top: 1px solid #CECECE; background: url(images/lixa.jpg); }
.cvpage .extra ul { padding: 0; list-style: none; }
.cvpage .extra ul li { margin: 30px 50px; display: inline-block; }
.cvpage .extra ul li a { font-size: 1.25rem; }
.cvpage .extra ul li i { font-size: 3rem; display: block; margin-top: 5px; color: #ff4000; } 
.cvpage .formbox { margin: 0 auto; border: none; border-bottom: 1px solid #cecece; width: 100%; padding: 30px 25%; }
.cvpage .formbox h3 { color: #495569; font-family: 'r100'; }
.main .cvpage .formbox h3 { color: #495569; font-family: 'r100'; }



/* PROJECTS */
.projpage:after { display: none; }
.projpage .mainheader { z-index: 1; }
.projpage h2.box { margin: 0 auto; z-index: 2; color: #828282; text-transform: uppercase; font-size: 2vw; line-height: 1; letter-spacing: 1vw; width: 33.33%; border: none; border-bottom: 1px solid #CECECE; padding-bottom: 15px; }
.projpage .allprojs ul { position: relative; z-index: 2; list-style: none; padding: 0; margin: 5vw 0 0 0; border-top: 1px solid #CECECE; background: url(images/lixa.jpg); }
.projpage .allprojs ul li { float: left; width: 33.33%; }
.projpage .allprojs ul li a { display: block; height: 25vw; padding: 2.5vw; border-bottom: 1px solid #CECECE; border-right: 1px solid #CECECE; }
.projpage .allprojs ul li:nth-of-type(3n + 3) a { border-right: none; }
.projpage .allprojs ul li a,
.projpage .allprojs ul li a * { transition: all .4s; -webkit-transition: all .4s; }
.projpage .allprojs ul li a .thumb { height: 75%; margin-bottom: 10px; background-repeat: no-repeat; background-position: top center; background-size: cover; }
.projpage .allprojs ul li a h3 { margin: 3px 0 3px 0; font-size: 1.3vw; text-transform: uppercase; color: #ff4000; }
.projpage .allprojs ul li a h3 i { color: rgba(0, 0, 0, 0.23); }
.projpage .allprojs ul li a p { margin: 0; color: #999; }
.projpage .allprojs ul li a:active h3,
.projpage .allprojs ul li a:active p,
.projpage .allprojs ul li a:hover h3,
.projpage .allprojs ul li a:hover p { color: #555; }
.projpage .allprojs ul li a:hover,
.projpage .allprojs ul li a:active { box-shadow: inset 0 0 16px rgba(0,0,0,0.15); -webkit-box-shadow: inset 0 0 16px rgba(0,0,0,0.15); }
.projpage .allprojs ul li.nextproj a { background: #d6d6d6; text-align: center; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; align-items: center; justify-content: center; -webkit-align-items: center; -webkit-justify-content: center; }
.projpage .allprojs ul li.nextproj a h3 { font-size: 3vw; color: #949494; }
.projpage .allprojs ul li.nextproj a:hover,
.projpage .allprojs ul li.nextproj a:active { background: #fff; box-shadow: none; -webkit-box-shadow: none; }
.projpage .allprojs ul li.nextproj a:hover h3,
.projpage .allprojs ul li.nextproj a:active h3 { color: #ff4000; }
.main.projpage .backtop { border: none; padding: 60px 0 40px 0; }
.main.projpage .backtop .box { margin-top: 0; }


/* Project detail */
.projectpage.main h1 { padding: 30px; width: 100%; border: none; border-bottom: 1px solid #cecece; margin: 0 auto; }
.projectpage.main .linha { top: 70px; }
.container { position: relative; }
.container .desc { position: relative; margin: 3.5vw auto; width: 100%; }
.container .desc .txt { position: relative; border-top: 1px solid #CECECE; padding: 30px 0; background: rgb(243 243 243); }
.container .desc .txt h5 { font-size: 22px; color: #ff4000; margin: 20px 30px 10px 30px; }
.container .desc .txt h6 { color: #777; margin-top: 5px; }
.container .desc .txt img { margin-top: 15px; max-width: 100%; }
.container .desc .txt p { color: #737272; font-family: 'r300'; max-width: 600px; margin: 0 auto; line-height: 1.6; }
.container .desc .txt p + p { margin-top: 10px; }

.container .desc .txt ul.techs { margin: 25px 0 10px 0; padding: 0; list-style: none; }
.container .desc .txt ul.techs li { display: inline-block; color: #6B6B6B; font-size: 12px; margin-right: 5px; }
.container .desc .txt ul.techs li ~ li:before { content: "/"; padding-right: 8px; }

.nav { position: relative; width: 100%; height: 70px; margin: 0; background: url(images/lixa.jpg); display: table; border: 1px solid rgba(0,0,0,.1); }
.nav a { position: relative; display: table-cell; height: 70px; width: 70px; color: #bbb; }
.nav a ~ a { border-left: 1px solid rgba(0,0,0,.1); }
.nav .ano { position: relative; display: table-cell; width: auto; padding: 21px; color: #fff; vertical-align: middle; text-align: right; }
.nav a i { font-size: 40px; position: absolute; left: 6px; top: 10px; }
.nav a i.icon-right-open-big { left: 8px; }
.nav a.visit { font-size: 21px; padding: 21px; }
.nav a.visit:hover { color: #205886; }
.nav .empty { display: table-cell; width: auto; }
.nav a.back i { left: -1px; }
.nav a.back i ~ i { left: 8px; }
.gallery .altnav { position: absolute; left: 0; right: 0; bottom: 0; width: 100%; width: calc(100% + 1px); }

.container .gallery { position: relative; width: 66.66%; background: #fff; margin: 0 auto; padding: 20px 20px 100px 20px; border: 1px solid #CECECE; border-bottom: none; }
.container .gallery img { display: block; margin: 0 auto; max-width: 100%; height: auto; -webkit-box-shadow: 0 10px 6px -6px rgba(0,0,0,.5); box-shadow: 0 10px 6px -6px rgba(0,0,0,.5); border: 1px solid #ccc; border-bottom-color: #999; border-bottom: none; }
.container .gallery img.noborder { border: none; }
.container .gallery img.noshadow { box-shadow: none; -webkit-box-shadow: none; }
.container .gallery p { margin: 20px 0 40px 0; text-align: center; color: #495569; }
.container .gallery p + p { margin-top: -30px; }
.container .gallery a.desktop { display: block; margin: 20px auto; width: 200px; border: 1px solid #eee; padding: 5px 20px; text-align: center; color: #888; }
.container .gallery a.desktop:hover { color: #495569; }
.logopage .container .gallery img + img { margin-top: 50px; }

.amn .nav { background: #1f476a; }
.amn .nav a { color: #3479B1; }
.amn .nav .ano { background: #3479B1; }
.amn .nav a:hover, .amn .nav a:active, .amn .nav a:focus { color: #ffd300; }

.amn.marinha .nav .ano { background: #2f5a80; color: #589ad4; }

.nj .nav { background: #548E3B; }
.nj .nav a { color: #66BB41; }
.nj .nav .ano { background: #66BB41; }
.nj .nav a:hover, .nj .nav a:active, .nj .nav a:focus { color: #fff; }

.iefp .nav { background: #007B38; }
.iefp .nav a { color: #fff; }
.iefp .nav .ano { background: #2C9D36; color: #ddd; }
.iefp .nav a:hover, .iefp .nav a:active, .iefp .nav a:focus { color: gold; }

.poch .nav { background: #41009a; }
.poch .nav a { color: #fff; }
.poch .nav .ano { background: #4e149c; }
.poch .nav a:hover, .poch .nav a:active, .poch .nav a:focus { color: #FAEB59; }

.espap .nav { background: #245284; }
.espap .nav a { color: #4692bb; }
.espap .nav .ano { background: #3579ba; }
.espap .nav a:hover, .espap .nav a:active, .espap .nav a:focus { color: #fff; }

.mj .nav { background: #17586a; }
.mj .nav a { color: #4eb7d5; }
.mj .nav .ano { background: #226e84; color: #4eb7d5; }
.mj .nav a:hover, .mj .nav a:active, .mj .nav a:focus { color: #fff; }

.lv .nav { background: #382862; }
.lv .nav a { color: #21b0a8; }
.lv .nav .ano { background: #543e8c; color: #fff; }
.lv .nav a:hover, .lv .nav a:active, .lv .nav a:focus { color: #fff; }

.kudos .nav { background: #f58e32; }
.kudos .nav a { color: #ffdfc2; }
.kudos .nav .ano { background: #FFA24F; color: #ffdfc2; }
.kudos .nav a:hover, .kudos .nav a:active, .kudos .nav a:focus { color: #fff; }

.amo .nav { background: #192c66; }
.amo .nav a { color: #5476a0; }
.amo .nav .ano { background: #17457e; color: #5476a0; }
.amo .nav a:hover, .amo .nav a:active, .amo .nav a:focus { color: #fff; }

.misc .nav { background: none; border-color: #bbb; }
.misc .nav a { background: rgba(0,0,0,0.2); color: #fff; }
.misc .nav .ano { background: rgba(0,0,0,0.1); }
.misc .nav a:hover, .amo .nav a:active, .amo .nav a:focus { color: #ff4000; }


/* 404 */
.div404 { position: relative; z-index: 1; width: 492px; height: 492px; max-width: 90vw; max-height: 90vw; margin: 2rem auto 0 auto; background: url(images/larry.jpg); box-shadow: 0 0 28px #A5A5A5; background-size: cover; background-position: center; }
.div404 + h5 { position: relative; z-index: 2; color: #fff; font-family: "r300"; font-size: 30px; margin: 0 auto; width: 492px; max-width: 90vw;  line-height: 1; padding: 12px 20px; background: #ff4000; text-align: center; }
.div404 + h5 + .p404 { color: #888; text-align: center; margin-bottom: 50px; padding: 0 20px; }
.div404 + h5 + .p404 a { color: #ff4000; }


/* SKILLS PAGE */
.main.skillspage { padding-top: 1vw; }
.skillspage .graph { position: relative; margin: 3rem auto 0 auto; border-top: 1px solid #cecece; padding: 1rem; background: url(images/lixa.jpg); z-index: 2; }
.skillspage .graph h3 { margin-top: .5rem; color: #5c5c5c; font-size: 1rem; text-transform: uppercase; letter-spacing: 5px; }
.skillspage .graph .hexchart { max-width: 50vh; margin: 1rem auto; }
.skillspage .graph ul { list-style: none; padding: 0; }
.skillspage .graph ul li { display: inline-block; margin-right: 1rem; color: #5c5c5c; font-family: 'r100'; }
.skillspage .graph ul li b { display: inline-block; margin: .5rem; font-family: Arial, Helvetica, sans-serif; font-family: 'r300'; }



/* * * * * * * * * * * * * MEDIA QUERIES * * * * * * * * * * * * */

@media (max-width:1399px) {
.main section.hall h4.intro { width: 50%; margin-top: 12vw; }
.main section.hall h4.intro { font-size: 1.5vw; }
.skills .td { font-size: 4vw; }
.heartbox { width: 80%; }
.heartbox .heart { height: 100%; top: 8%; }
.heart .gotoproj h3:after { top: 5px; }
.biobox ul li .half p { padding-left: 25%; }
.biobox ul li .half.dir p { padding-right: 25%; }
.biobox ul li .half h2 { font-size: 2.5vw; }
.cube p { font-size: 3vw; }
.cube p a span { font-size: 1.5vw; }
.projectpage.main section > h2 { letter-spacing: 1vw; }
.projectpage .desc { margin: 11vw auto; width: 80%; }
.projectpage .gallery { width: 80%; }
}

@media (max-width: 991px) {
.mobiledevice { display: block; }
.mobiledevicehidden,
.main section.contact p.altctct { display: none; }
.altcontact ul li a { color: #888; }
.mainheader { position: fixed; top: 0; left: 0; width: 100%; z-index: 1982; }
.mainheader .square { position: absolute; top: 0; left: 0; width: 100%; }
.mainheader .square { padding: 20px; height: auto; }
.mainheader .square a { position: relative; display: block; }
.mainheader .square.logo a img { width: auto; height: 25px; }
.main { padding-top: 4rem; }
.main h1 { margin-top: 80px; }
.skillbox { width: 100%; left: 0; position: relative; display: block; height: auto; margin: 19vw 0; }
.skillbox .skills { width: 100%; left: 0; position: relative; display: block; height: 4vw; top: auto; }
.skillbox .skills .tr:after { clear: both; }
.skillbox .skills .tr .td { width: calc( 100% / 14); display: block; float: left; height: auto; min-height: 2px; }
.main section.hall h4.intro { font-size: 2.5vw; }
.main section.work > h2,
.main section.work > h2 { margin: 19vw auto 0 auto; border-bottom: none; }
.heartbox { width: 100%; border-left: none; border-right: none; height: 80vw; border-top: 1px solid #CECECE; }
.heartbox .heart { width: 50vw; height: 50vw; left: 25vw; top: 25%; }
.heart .gotoproj { left: 4%; }
.heart .gotoproj p { font-size: 1.5vw; }
.heart .gotoproj h3 { font-size: 3vw; }
.heart .gotoproj h3:after { display: none; }
.heart .gotoproj:active,
.heart .gotoproj:hover { left: 6%; }
.biobox ul li .half h2 { font-size: 3.5vw; width: 100%; margin: 0; }
.biobox ul li .half.esq h2 { margin-right: -30px; padding-left: 0; }
.biobox ul li .half img { max-height: 100px; max-width: 200px; }
.main section.contact > h2 { width: 50%; margin-bottom: 0; }
.main.ctctpage { padding-top: 7vw; }
.main.ctctpage h2.box { margin-top: 9vw; }
.passwordpage .formbox { width: 80%; margin: 150px 10% 0 10%; }
.passwordpage .formbox h2 { width: 100%; }
.main section.cvpage > h2.box { padding-top: 100px; margin-top: 0; font-size: 3.5vw; }
.main section.cvpage > h2.box.download { padding: 70px 30px; }
.cvpage a.download { width: 66%; }
.cvpage .box.extra { margin-top: 80px; }
.cvpage .formbox { padding: 30px; }
.table.bck .tr .td { height: 10vw; }
.table.bck .tr .td:nth-of-type(n + 10) { display: none; }
.projectpage.main h1 { margin-top: 20px; }
.projectpage.main .linha { top: 100px; }
.projpage .allprojs ul { margin-top: 10vw; }
.projpage .allprojs ul li { width: 50%; }
.projpage .allprojs ul li a { height: 40vw; }
.projpage .allprojs ul li a h3 { font-size: 2vw; }
.projpage .allprojs ul li a,
.projpage .allprojs ul li:nth-of-type(3n + 3) a { border-right: none; }
.projpage .allprojs ul li:nth-of-type(2n + 2) a { border-left: 1px solid #CECECE; }
.projectpage .container { min-width: 0; max-height: none; padding: 0; }
.projectpage .desc { width: 100%; float: none; }
.projectpage .desc .txt { padding: 30px 20px; border-left: none; border-right: none; }
.container .desc .txt h5 { margin: 20px 0 10px 0; }
.container .desc .txt p { margin: 0; line-height: 1.4; }
.container .gallery { float: none; width: 100%; margin: 0; border-left: none; border-right: none; }
.container .gallery img { width: auto; }
.container .gallery a.back.movel { position: absolute; left: 50%; bottom: -30px; color: #fff; margin-left: -25px; font-size: 30px; width: 70px; }
.container .gallery a.back.movel i { position: absolute; left: -1px; }
.container .gallery a.back.movel i ~ i { left: 5px; }
.main.skillspage h2.box { margin-top: 11vw; font-size: 1.5rem; width: 66%; }
.skillspage .graph h3 { text-align: center; }

/* Contacts Mobile only */
.listcontact { position: relative; margin-top: 7vw; background: url(images/lixa.jpg); }
.listcontact ul { list-style: none; padding: 20px 0; margin: 0; border-top: 1px solid #CECECE; }
.listcontact ul li a { display: block; font-size: 44px; padding: 20px; }
.listcontact ul li a p { margin: 10px 0 0 0; color: #828282; font-size: 20px; }

}

@media (max-width: 550px) {
.smalldevice { display: block; }
.smalldevicehidden { display: none; }
.mainheader .square { padding: 10px; }
.heartbox .heart { width: 80vw; height: 80vw; left: 10vw; top: 8%; }
.main h1 { width: 100%; border-left: none; border-right: none; }
.skills .td { font-size: 6vw; }
.skillbox .skills { height: 6vw; }
.main section.hall h4.intro { width: 75%; font-size: 4vw; }
.cube p { font-size: 35px; }
.cube p a span { font-size: 22px; }
.biobox ul { margin-top: 14vw; background: url(images/lixa.jpg); border-bottom: 1px solid #CECECE; border-top: 1px solid #CECECE; }
.biobox ul li:first-child { margin-top: 14vw; }
.biobox ul li .half { float: none; width: 100%; text-align: center; padding: 0; }
.biobox ul li .half.esq { text-align: center; }
.biobox ul li .half.dir h2,
.biobox ul li .half.esq h2 { padding: 20px 20px 5px 20px; font-size: 6.5vw; margin: 0; border: none; }
.biobox ul li .half.dir p,
.biobox ul li .half.esq p { text-align: center; padding: 0 20px; margin-top: 0; }
.main section.cvpage > h2.box { font-size: 4.5vw; }
.cvpage p.box { margin: 30px auto 0 auto; }
.cvpage p.box i { font-size: 5vw; }
.projpage .allprojs ul li { width: 100%; float: none; }
.projpage .allprojs ul li a { height: 100vw; padding: 25px; }
.projpage .allprojs ul li a h3 { font-size: 5vw; margin-top: 20px; }
.projpage .allprojs ul li a { border: none; }
.projpage .allprojs ul li.nextproj a h3 { font-size: 7vw; }
.projectpage.main { padding-top: 14vw; }
.projectpage .desc { margin: 0; border-bottom: 1px solid #cecece; }
.projectpage .linha,
.projectpage .desc .nav { display: none; }
.skillspage { text-align: left; }
.skillspage .graph ul li { display: block; }
.cvpage a.download { width: 66%; }
.cvpage .extra ul li { margin: 30px auto; display: block; }
.cvpage .extra ul li a { font-size: 1.25rem; }
.cvpage .extra ul li i { font-size: 2.25rem; } 

}